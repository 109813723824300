
.planning{
  font-weight: 500;
  width: 93vw;
  height: 66vh;
  display: flex;
  grid-template-columns: repeat(3,1fr);
  grid-gap: 3px;
  color: black;
  user-select: none;
}

.planning .planning-card a{
  color: #00009b;
  border: 1px solid white;
  border-radius: .3rem;
  background-color: rgba(255, 255, 255, 0.7);
  padding: .1rem .7rem;
  margin: .3rem;
}
.planning .container-list{
  display: grid;
  overflow: auto;
  grid-column: 1/2;
  width: inherit;
}

.travelTimeButton .LoadingSVG{
  width: 3rem;
  filter: blur(5);
}


.container-list thead  {
  position: sticky;
  top: 0;
}



.planning .step-date{
  font-size: .8rem;
  color: var(--text);
  text-align: center;
  text-overflow: ellipsis;
  max-width: 2rem;
  overflow: hidden;
}

.container-list .container-table{
  padding-left: 2.9rem;
}
.container-list .container-table table {
  border-collapse: collapse;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.38);
}

.planificator h2{
  display: inline-block;
  padding-right: 1rem;
}
.form input#week{
  height: 2.4rem;
  font-family: Rubik, sans-serif;
  padding-left: .3rem;
  width: 12rem;
}
.planningHead{
  display: flex;
  grid-gap: .2rem;
  background-color: rgba(245, 245, 245, 0.34);
  z-index: 11;
  border-radius: 5rem;
  padding: .8rem;
  margin: 1rem auto;
  width: fit-content;
}

.planificator .form input[type="date"]{
  width: 15%;
  padding: .4rem;
  font-family: rubik, sans serif;
  color: #999;
  font-size:.95rem;
}

.planningHead .savePlanning .MuiIconButton-root.validation-button:before {
  transform: scale(.7);
  content: url('../images/save-icon.png');
  margin: 0 .5rem 0 -.7rem;
}


.planningHead .savePlanning{
  grid-area: e;
  margin: auto .5rem;
}

.planificator .planningHead .form {
  grid-area: s;
  width: fit-content;
  background-color: rgba(0, 129, 189, 0.24);
  border: solid 1px rgba(0, 55, 128, 0.267);
  margin: .1rem;
  padding: .3rem;
  border-radius: 5rem;
  z-index: 10;
}



.planning .form .select{
  font-family: rubik, sans serif;
  font-size: 1rem;
  padding: .5em;
  height: 1.5rem;
}
.form label {
  font-weight: 800;
}

.planningHead  .travelTimeButton{
  border: rgba(0, 0, 0, 0.24) 1px solid;
  border-radius: 3rem;
  margin: auto .5rem;
}

.form input{
  height: 1.7rem;
  width: 12rem;
  border: var(--input-border-color) solid 1px;
  border-radius: .3rem;
}
.form input:active{
  border:var(--input-color) 2px solid;
}
.form > *{
  display: inline-block;
  margin: 0 1rem;
}

.form .team-selector{
  width: 16rem;
  color: black;
}

