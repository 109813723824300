.clientForm {
    width: 35.5rem;
    background-color: rgba(255, 255, 255, 0.336);
    min-height:30rem ;
    display: grid;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
}

.clientForm .child {
    padding-top: 10px;
    margin: 0 auto;
    width: 70%;
}

.clientForm .child h2{
    color: #666;
}
.clientForm .client-address{
    border: 1px solid rgba(255, 255, 255, 0.774);
    border-radius: .8rem;
    padding: .3rem .5rem .3rem .2rem;
    width: 24.5rem;
    margin: .2rem 0;
}


.clientForm .client-address input#cp{
    width: 30%;
}

.clientForm .client-address input#ville{
    width: 66%;
    }
    

.wrapper {
    margin-top: 10px;
    width: 100%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clientForm input::placeholder{
    color: black;
}

.clientForm input, .clientForm select {
    margin: 0.2rem;
    border-radius: .5rem;
    width: 100%;
    box-sizing: border-box;
    box-shadow: inset rgba(0,0,0,0.1) 1px 2px 19px ;
    background-color: transparent;
    font-family: rubik;
    font-size: 16px;
    padding: 14px 2px 12px 10px;
    border: 1px solid white;
}