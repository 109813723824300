
.interaction .entree{
    border: 1px solid rgb(255, 255, 255);
    border-radius: 1rem 1rem 4rem 4rem;
    margin-bottom:0.5rem ;
}
.interaction .entree textarea{
    width: 94%;
    height: 5rem;
    margin: .5rem;
    padding: .5rem;
    font-family: rubik;
    border-radius: 0.3rem;
    background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid #b49c00;
}

.interaction label{
    font-size: .8rem;
    font-weight: 700;
}

.interaction .teamInteraction{
    background-color: rgba(255,255,0,0.2);
    border-radius: 1rem;
    border: 1px solid #b49c00;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.interaction .teamInteraction> *{
    margin: 1rem;
}

.interaction .teamInteraction textarea{
    border-radius: 0.4rem;
    font-family: rubik;
    padding: .5rem;
    margin: .5rem;
    width: 94%;
    height: 4rem;
    border: 1px solid #b49c00;
    background-color: rgba(255, 255, 255, 0.8);
}
.interaction .outputs{
    border: 1px dashed #EEE;
    width: 30rem;
    min-height: 20rem;
    padding: .5rem;
    border-radius: 0.7rem;
    background-color: rgba(255, 255, 255, 0.514);
    height: 17rem;
    overflow-y: scroll;
}


.interaction .outputs >div{
margin: .5rem;
    color: black;
background-color: rgba(255, 255, 255, 0.788);
border-radius: .5rem;
padding: .5rem;
box-shadow: rgba(0, 0, 0, 0.322);
}

.interaction .outputs::before {
    background-color: rgba(255, 255, 255, 0.801);
    border-radius: 1rem;
    padding: 0 .5rem;
    margin-left: 13%;
    font-weight: bold;
    color: rgba(0, 0, 128, 0.432);
    content: "1000 Caractères maximum par message";
  }

.interaction .entree > *{
    margin: 0.5rem;
}
.interaction .entree .buttons{
    display: grid;
    padding: .4rem;
    border-radius: 5rem;
    background-color: rgba(0, 123, 155,0.6);
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: repeat(2,1fr);
    margin: .2rem 1.5rem 1.5rem 1.5rem;
}

.interaction .entree .buttons button{
    background-color: transparent;
    cursor: pointer;
    border: none;
    margin: -.5rem;
    padding: 0;
}