.company-creation:before{
    content: url('../../images/new-user.png');
    margin: 1.2rem;
}

.company-creation{
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    text-align: center;
    width: 22rem;
    background-color: rgba(255, 255, 255, 0.336);
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid white;
    margin: 1rem auto;
}

.company-creation .owner{
    padding: 1rem;
    border-radius: 1rem;
    border: 1px solid white;
}

.company-creation h2{
    font-size: 1.3rem;
    font-weight: 700;
    margin: 1rem auto;
}

.company-creation .owner:after{
    font-size: .8rem;
    color: rgba(0, 0, 0, 0.596);
    }

.company-creation input{
    margin: .1rem auto;
    height: 2rem;
    padding-left: .5rem;
    border-radius: .3rem;
    border: 1px rgba(128, 128, 128, 0.644) solid;
    background-color: rgba(255, 255, 255, 0.384);
    box-sizing: border-box;
    width: 100%;
}

.company-creation input[name="cp"]{
   width: 29%;
   margin-right: 1%;
}


.company-creation input[name="ville"]{
    width: 70%;
}
.company-creation .company{
    padding: 1rem;
    border: 1px solid rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.418);
    border-radius: .5rem;
    margin: 1rem auto;
}

.company > *{
    display:block;
}

.company-creation a{
    display: block;
  font-size: .9rem;
    background-color: rgba(240, 248, 255, 0.103);
}

.company-creation .logo-to-upload{
    border: 1px solid white;
    border-radius: .5rem;
    padding: .5rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    font-size: .8rem;
}

.company-creation .fileUploader{
    margin: auto;
    padding: .5rem;
}

.company-creation .validation-button{
    display: block;
    margin: 1rem auto;
}