
td.step-container{
    padding-y: 2rem;
    width: 10vw;
    white-space: normal;
    vertical-align: top;
}

.step-container .agenda-container{
    position: relative;
    border-left:  solid rgba(96, 96, 96, 0.37) 1px;
    border-right:  solid rgba(255, 255, 255, 0) 1px;
}

.step-container .agenda-container:nth-child(4n+1){
    border-top: dashed rgba(0, 0, 0, 0.25) 1px;
}

.step-container:first-child .agenda-container:nth-child(4n+1):before{
    content: attr(name);
    font-size: .85rem;
    font-weight: normal;
    color: var(--text);
    top: -2px;
    position: absolute;
    opacity: 1;
    left: -2.8rem;
    z-index: 0;
}








