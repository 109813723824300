.supply{
    max-width: 95vw;
    margin: .5rem;
    display: grid;
    font-family: rubik;
    grid-template-areas: "m a"
                         "d n";
    grid-gap: .5rem;
}
.supply .cbox{
    display: flex;
    justify-content: space-evenly;
    background-color: var(--cbox-background);
    color: var(--cbox-color);
    font-family: Rubik,sans-serif;
    font-weight: 700;
    font-size: 0.5rem;
    padding: 0.5%;
    border-radius: 1rem;
    grid-area: c;
}
.supply .material{ 
    white-space: nowrap;
    padding: 0.5%;
    font-family: rubik;
    background-color: var(--supply-material);
    border-radius: 1rem;
    grid-area: m;
    font-size: 0.6rem;
}
.supply .material input{
    height: 1.25rem;
}

.supply .machinery{
    padding: 0.5%;
    white-space: nowrap;
    background-color: var(--supply-machinery);
    border-radius: 1rem;
    grid-area: n;
    font-family: rubik;
    font-size: 0.6rem;
}

.supply .machinery input select{
    font-family: rubik;
        border: 1px solid var(--select-border);
    background-color: var(--select);
    max-width: 3rem;
}

.supply .document{
    padding: 0.5%;
    white-space: nowrap;
    border-radius: 1rem;
    background-color: var(--supply-doc);
    grid-area: d;
    font-size: 0.6rem;
}


.supply .adminDocument{
    padding: 0.5%;
    white-space: nowrap;
    border-radius: 1rem;
    background-color: var(--admin-doc);
    grid-area: a;
    font-size: 0.6rem;
}
.supply .customImage{
    cursor: pointer;
    padding: .2rem;
    border: 1px #ffffff dashed;
    border-radius: .3rem;
    background-color: rgba(155, 155, 155, 0.55);
    margin: .5rem;
}

.supply .type{
    max-width: 100vw;
    min-height: 20vh;
    min-width: 100%;
}

.supply .type ul{
    min-height: 10%;
    max-height: 30vh;
    overflow: auto;
    border: rgba(0, 0, 0, 0.15) 1px solid;
}

.supply ul li{
    list-style: none;
    padding-left: .5rem;
    color: black;
    font-size: .7rem;
}
.supply .add-section,.supply .img-container{
    display: inline-block;
    white-space: nowrap;
    margin: auto;
}

.supply .materialName{
    border-radius: .5rem;
    margin-right: .2rem;
    padding: .2rem;
    border: var(--div-border) 1px solid;
    min-width: 15rem;
    max-width: 15rem;
    overflow: auto;
    text-overflow: unset;
}

.supply .add-section .container{
    background-color: var(--green-rounded-form-div);
    border-radius: 3rem;
    padding: .3rem 1rem;
    overflow: auto;
    margin: auto;
}

.supply .container >*{
    margin: 0 0.5rem;
    max-width: 8rem;

}
.supply li button{
    width: fit-content;
}
.supply li:nth-child(odd) {
    background-color: var(--li-odd-color);
    border-radius: .5rem;
}
.supply ul{
    background-color:var(--ul);
    padding: .7rem .2rem;
    margin: .5rem;
    border-radius: 1rem;
}
.supply input:not([type="checkbox"]){
    padding: 0.5rem 0 0.5rem  0.4rem;
    border-radius: 0.28rem;
    font-family: rubik;
    border: 1px solid var(--select-border);
    background-color: var(--input-color);
    min-width: 5rem;
    max-width: 15rem;
}

.supply input[type=number]{
    max-width: 2rem;
    min-width: 1.5rem;
    padding: .3rem;
}

.supply select{
    padding: 0.6rem 0 0.6rem  0.4rem;
    border-radius: 0.28rem;
    border: 1px solid var(--select-border);
    width: fit-content;
}

.supply .listItem{
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.listItem  p{
    display: block;
    width: 40%;
    line-break: auto;
    overflow: auto;
    font-size: 0.8rem;
    border: 1px var(--div-border) solid;
    padding: .3rem;
    border-radius: .5rem;
    margin: 0 .2rem;
}

.listItem input.fileUploader{
    visibility:hidden;
    width: 0.1px;
    position: absolute;
    height: 0.1px;
}
.listItem label.fileUploader::before{
    content: url("../../images/clipper.png");
    display: block;

}
.supply .listItem label{
    grid-column: 7;
    justify-self: right;
    margin-right:0.5rem;
}



@media (max-width: 1580px) {
    .supply{
        max-width: 90vw;
        min-width: 30rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        grid-gap: 0.5rem;
    }

}



