.siteForm {
    width: 1000px;
    height: 80vh;
    /*background: #f1f1f1;*/
    display: flex;
    flex-direction: column;
}
.siteForm h2{
    color: rgb(0, 0, 0);
    margin-bottom: 0;
}

.siteForm .search-photo{
    align-self: center;
}

.siteForm .name{
    padding: .2rem;
    width: 50vw;
    display: inline-grid;
    margin: auto;
}

.siteForm .container .mapPointer{
    padding-top: 0;
}
.siteForm .name input{
    width: 45vw;
    padding: 14px 20px 12px 14px;
    font-family: rubik;
    font-size: 16px;
    border-radius: .5rem;
    border: 1px solid rgba(0, 0, 0, 0.41);
    margin: .3rem auto;
}
 
.siteForm .container {
    display: grid;
    flex-direction: row;
    justify-content: space-around;
}

.siteForm .wraper {
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    justify-content: center;
}
