.client-page{
  display: block;
  margin: auto auto 2rem;
  bottom: 0;
  right: 0;
  left: 0;
}
.client-page .client-head{
  display: flex;
  justify-content: space-around;
  border: 1px white solid;
  background-color: rgba(255,255,255,0.25);
  backdrop-filter: blur(2px) ;
  border-radius: 1rem;
  width: fit-content;
  padding: 2rem 5rem;
  gap: 1rem;
  margin: auto auto 1rem;
}

.client-page .client-head div{
  border: 1px solid rgba(255, 255, 255, 0.54);
  padding: .5rem 1rem;
  border-radius: .5rem;

}

.client-page .table_graph .intervTitle1{
  font-size: .9rem;
  font-weight: 600;
  display: inline;
  margin: auto;
  padding: .2rem;
  background-color: rgba(255, 255, 255, 0.31);
}

.client-page .table_graph th:first-of-type{
  text-align: left;
  max-width: 5%;
  width: 5%;
  background-color: rgba(255, 255, 255, 0.32);
}

.client-page .table_graph{
  padding: .5rem;
}


.client-page .table_graph table{
  border-collapse: separate;
  border: solid var(--editable-border) 1px;
  border-radius: .7rem .7rem .3rem .3rem;
  padding: .2rem .1rem;
}
.client-page .table_graph .tableMain table{
  border-collapse: separate;
  border: solid var(--editable-border) 1px;
  border-radius: .7rem .7rem .3rem .3rem;
  padding: .2rem .1rem;
}



.client-page .dx-visibility-change-handler{
  background-color: rgba(255, 255, 255, 0.44);
  border: 1px solid var(--div-border);
  border-radius: 0 .8rem .8rem 0;
  padding: 1rem;
  align-content: center;
  width: -webkit-fill-available;
  /*max-width: 35vw;*/
}

.client-page h2{
  margin: auto;
  font-size: 1.8rem;
  padding: 0 2rem;
  color: var(--text);
}

.client-page .imgClient{
  position: absolute;
  opacity: .5
}

.client-page .PartTabGraph{
  border: var(--div-border) 1px solid;
  margin: .5rem;
  border-radius: 1rem;
  display: inline-flex;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.25);
  width: --webkit-fill-available;
}
.client-page .PartTabGraph.onRow{
  display: grid;
}
.client-page .PartTabGraph.onRow *{
  max-width: 100%;
}
.client-page .table_graph .PartTabGraph .tableMain tr td{
  width: 100%;
  border: none;
}
.client-page .PartTabGraph table th.header{
  text-align: center;
}

@media only screen and (max-width: 1920px) {
  .client-page .client-body{
    border: 1px solid var(--editable-border);
    margin: auto;
    border-radius: 1rem;
  }
}
@media only screen and (min-width: 1920px) {
  .client-page .client-body{
    border: 1px solid var(--editable-border);
    margin: auto;
    border-radius: 1rem;
    width: 70vw;
    padding: 0;
  }
}

.client-page .client-head > div:before{
  content: attr(title);
  display: block;
  font-weight: bold;
  color: #000000ab;
}
.client-page .client-head #gestionnaire{
  /*width: 20rem;*/
}
#clientPlanningHead{
  z-index: 0;
}

.client-page .client-body table.tableMain{
  border: none;
}
.client-page .client-body .table_graph .PartTabGraph table.tableMain tr.noBackground{
  background: none;
}
.client-page .client-body .table_graph .PartTabGraph table.tableMain th{
  background: none;
}

.client-page .client-body .table_graph .PartTabGraph table.statsTable th{
  background: rgba(255, 255, 255, 0.25);
  font-weight: 900;
}
.client-page .client-body .table_graph .PartTabGraph table.statsTable td{
  border: solid 1px var(--div-border);
  text-align: center;
  border-collapse: collapse;
  height: fit-content;
}

.client-page .client-body .table_graph .PartTabGraph table.statsTable4{
  width: -webkit-fill-available;
}
.client-page .client-body .table_graph .PartTabGraph table.statsTable4 th{
  max-width: 10vw;
}
.client-page .client-body .table_graph .PartTabGraph table.statsTable4 td{
  max-width: 10vw;
}
.client-page .client-body .table_graph .PartTabGraph table.statsTable{
  border: none;
  border-collapse: collapse;
}

.client-page .client-body table{
  border-collapse: collapse;
  border: var(--editable-border) 1px solid;
  height: fit-content;
  display: block;
  margin: auto 0;
}
.client-page .client-body table th{
  border: solid 1px var(--div-border);
  font-size: 1rem;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.33);
  color: #000000bd;
  height: 1rem;

}

.client-page .client-body .table_graph .PartTabGraph table.statsTable td .rawNumber{
  padding-left: 3px;
  width: 49%;
  border: none;
  text-align: end;
}
.client-page .client-body .table_graph .PartTabGraph table.statsTable td .separator{
  width: 1%;
  border: none;
}
.client-page .client-body .table_graph .PartTabGraph table.statsTable td .percentRate{
  padding-right: 3px;
  font-weight: bold;
  font-size: .9rem;
  width: 49%;
  border: none;
  text-align: start;
}

.planningHead .createSite{
  width: 25%;
}
