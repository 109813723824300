
.step-flat:not(.step-flat.fix){
    background: var(--stepflat-color);
    border: solid 2px rgba(255, 255, 255, 0.4);
    border-radius: .3rem;
    text-shadow:0 0 3px rgba(0, 0, 0, 0.34);
    color: var(--text);
    cursor: grab;
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    top: 0;
    opacity: .93;
    transition: all .2s ease-in;
}

.step-flat{
    padding: .1rem;
    margin-left: .01rem;
    width: 94%;
}
.step-flat .exit .MuiIconButton-label{
    display: flex;
    align-items: inherit;
    justify-content: flex-end;
}

.step-flat .exit{
    font-family: Rubik, sans serif;
    color: rgba(0, 0, 0, 0.75);
    font-size: .9rem;
    font-weight: bold;
    display: flex;
    justify-content: flex-end;
    width: fit-content;
}

.step-date{
    font-size: .8rem;
    color: var(--text);
    text-align: center;
}
.step-flat nav{
    border: 1px rgba(255, 255, 255, 0.92) solid;
    background-color: rgb(255, 255, 255);
    border-radius: .8rem;
    padding: .1rem .2rem;
}

.step-flat:not(.content, .step-flat.fix):hover{
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.15);
    top: -2px;
    font-weight: 600;
    background-color: var(--stepflat-color-hover);
    border: 1px rgba(255, 255, 255, 0.6) solid;
    color: var(--text);
    transition: all .1s ease-in;
    opacity: 1;
    animation: pulse-white .5s ease;
}

@keyframes pulse-white {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.52);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.step-flat.fix{
    border-radius: .3rem;
    border: rgba(21, 32, 66, 0.6) 1px dashed;
    background: linear-gradient(rgba(5, 50, 66, 0.67), rgba(39, 105, 145, 0.56));
    color: #dcdcdc;
    text-shadow: 0 0 3px black;
    position: absolute;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
    width: 95%;
    top: 0;
    opacity: .9;
    cursor: no-drop;
}

.step-flat .date-intervention::before{
    content: url("../images/icon-cal.png");
    margin-right: .5rem;
}


.step-flat.content .date-intervention{
    font-size: .8rem;
    background-color: rgba(0, 0, 0, 0.57);
    border: 1px solid rgba(255, 255, 255, 0.69);
    padding: .3rem .5rem;
    color: #ffffff;
    border-radius: .5rem;
    cursor: default;
}

.popper{
    position: absolute;
    top: 5rem;
    z-index: 2;
}

.step-flat.content {
    cursor: auto;
    top: 4rem;
    background-color: rgba(219, 255, 252, 0.52);
    padding: .2rem .5rem;
    border: solid 1px rgb(255, 255, 255);
    border-radius: .7rem;
    width: 10rem;
    min-height: fit-content;
    position: relative;
    display: inline-grid;
    box-shadow: 0 2px 2px 3px rgba(0,0,0,.2);
    grid-gap: .3rem;

}
.step-flat.content span:active:not(.date-intervention){
    cursor: pointer;
    opacity: 0.2;
    transition: .3s all ease-in-out;
    background-color: rgba(255, 255, 255, 0.233);
}

.step-flat.content span:hover:not(.date-intervention){
    opacity: 0.6;
    transition: .3s all ease-in-out;
    cursor: pointer;
}

.step-flat.content span.delete{
    margin: .3rem .3rem .3rem 2.2rem;
    content: url("../images/delete.png");
}

.step-flat.content span.appro{
    content: url("../images/case2.png");
    transform: scale(.7);
    margin: .2rem;
}
.step-flat.content span.time{
    content: url("../images/duration.png");
    margin: .2rem;
}
.step-flat.content span.contact{
    content: url("../images/contact-interaction.png");
    margin: .2rem;
}
