.editIntervention {
    display: flex;
    margin: 30px;
}

.editIntervention .container{
    display: inline-block;
}

.editIntervention .img-container{
   margin-top: 25px;
   margin-right: 25px;
}

.editIntervention input,.editIntervention select {
    box-sizing: border-box;
    background-position: 14px 12px;
    background-repeat: no-repeat;
    font-size: 16px;
    margin: 1rem;
    border-radius: .3rem;
    padding: .5em .5rem .5rem .9rem;    
    border: 1px solid #b49c00;
    background-color: rgba(255, 255, 255, 0.8);
    font-family: rubik;
}

.editIntervention {
    overflow: auto;
}

.editIntervention ul{
    z-index: 1;
}

.editIntervention button{
    font-family: Nunito;
    font-size: 1.5rem;
    font-weight: bold;
    margin: 1.5rem 0;
    color: white;
    background-color: rgb(23,113,8);
    padding: 1rem 2rem;
    border-radius: 2rem;
}

.selecTeam{
    background-color: rgba(255, 255, 255, 0.52);
    margin: .5rem;
    padding: 1rem 3rem;
    border-radius: 3rem;

}


.editIntervention button:hover{
    opacity: .8;
}

.editIntervention .search__menu{
    position: static;
}

.editIntervention .MuiIconButton-root.validation-button{
    display: block;
    text-align: right;
    margin: auto;
}

.editIntervention .proDepro{
    display: grid;
    background-color: rgba(141, 141, 141, 0.68);
    border-radius: 2.5rem;
    padding: .5rem 2.5rem;
    margin: 1rem;

}
