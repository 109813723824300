
.login{
    font-family: Nunito, sans-serif;
    display: grid;
    margin: auto;
    margin-top: -5rem;
    width: 300px;
    padding: 30px;
    background:inherit;
    background-color: rgba(194, 194, 194, 0.1);
    position: absolute;
    border: solid 2px rgba(207, 207, 207, 0.219);
    right: 50%;
    top: 30%;
    border-radius: 24px;
    transform: translateX(50%);
    backdrop-filter: blur(2px) brightness(109%);
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.25) 0 19px 35px;

}
.login div{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 2.5rem .5rem;
    margin: .7rem;
    background: inherit;
    border-radius: 4rem ;
    background-color: rgba(255, 255, 255, 0.23);
    min-width: 250px;
}

.login input{
    padding: 10px;
    border-radius: .3rem;
    width: 10rem;
    border: 1px solid rgba(90, 90, 90, 0.349);
    margin: .1rem;
    background-color: rgba(255, 255, 255, 0.48);
    transition: width 0.1s ease-in-out;
}

.login input:hover{
border: 1px rgba(255, 255, 255, 0.64) solid;
    background-color: rgba(255, 255, 255, 0.44);
}

.login input:focus{
    width: 200px;
    outline: none;
}

.login img{
    justify-self: center;
}

.login h3{
    justify-self: center;
    color: var(--text);
    font-size: 1.9em;
    font-family: rubik;
    font-weight: 800;

}

.login button{
    background-color: #0f9800;
    font-size: .4rem;
    font-family: Nunito, sans-serif;
    border-radius: 50px;
    border: solid 1px rgba(78, 208, 129, 0.8);
    justify-self: center;
    padding: 1.5rem;
    margin-top: 20px;
    color: white;
    transition: .5s ease-in-out all;
}

.login button:hover{
    box-shadow: rgba(0, 0, 0, 0.12) 0px 5px 25px;
    transition: .2s ease-in-out all;
}

.login a{
    margin: 1rem 0 -1rem 0;
    padding: .2rem 2rem;
    border-radius: 1rem;
    border: 1px solid rgba(255, 255, 255, 0.397);
    background-color: rgba(240, 248, 255, 0.34);
}