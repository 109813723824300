.documentLoader{
    width: fit-content;
}

.customModalPDF{
    width: 50vw;
    height: 70vh;
}


.customImageModal{
    margin: auto;
    padding: 1rem;
    width: 30vw;
    max-height: 90%;
    overflow: scroll;
    transition: all 1.5s ease-in-out;
    transition-delay: .5s;
    cursor: zoom-in;
    z-index: 5;
}


.customImageModal::before{
    content: 'heyehye'
}

.customImageModal:hover{
    width: 60vw;
    max-height: 80%;
    overflow: scroll;
    -webkit-transform: scale(.9);
    transform: scale(.9);
    cursor: zoom-in;
    z-index: 5;
}

.documentLoaderList{
    width: fit-content;
    display: grid;
    font-family: rubik;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 1rem;
}
