
.devisForm {
    display: grid;
    min-width: 100%;
    height: 100%;
    grid-template-areas:
    "i d d f f"
    "k n n m m" 
    "k c c p p";
    grid-gap: 0.5rem;
}
.devisForm > div{
    border-radius: 1rem;
    padding: 1rem 0 1rem 1rem;

    
}
.devisForm li{
    list-style: none;
}
.devisForm li:nth-child(odd){
    background-color: var(--li-odd-color);
}
.devisForm select{
    padding: .6rem 0.6rem .6rem  0.4rem;
    border-radius: 0.28rem;
    border: 1px solid var(--select-border);
}
.devisForm input{
    padding: 0.5rem 0.5rem .5rem  0.4rem;
    margin: 0 2rem;
    border-radius: 0.28rem;
    border: 1px solid var(--input-border-color);
    min-width: 9.5rem;
    font-family: rubik;
    background-color: var(--input-color);
}
.devisForm input#id{
    width:20rem;
}

.devisForm .icon{
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: i;
}

.devisForm .document{
    background-color: var(--devis-document);
    grid-area: d;
    padding: 1rem;

}
.devisForm .document > div{
    background-color: var(--green-rounded-form-div);
    border-radius: 5rem;
    padding: 1rem 3rem;
    width: 25rem;
}

.devisForm .machineries{
    background-color: var(--devis-machineries);
    grid-area: n;
}
.devisForm .machineries > div > div{
    background-color: var(--green-rounded-form-div);
    border-radius: 5rem;
    padding: 1rem 3rem;
    width: 24rem;
}
.devisForm .comments{
    padding: 1rem;
    background-color: var(--comment-div-color);
    grid-area: m;
}
.devisForm .comments > div:not(.container) {
    padding: 0;
    display: block;
}
.devisForm .comments .container {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    height: 90%;
}
 .devisForm .comments .container textarea{
     margin-bottom: 1rem;
     min-height: 40%;
     font-family: rubik;
     border-radius:0.3rem;
 }

 .devisForm h3{
     color: black;
 }

.devisForm .devisData{
    background-color: var(--devis-data);
    grid-area: p;
}
.devisForm .devisData ul{
    background-color: var(--ul);
    width: 90%;
    max-height: 10rem;
    padding: 1rem;
    overflow: auto;
    border-radius: 0.5rem;
}

.devisForm .devisData li{
    list-style: inherit;
    padding: .3rem;

}

.devisForm .commande{
    background-color: var(--commande);
    grid-area: c;
    padding:0 1rem;
}

.devisForm .commande >input{
    margin-bottom: 0.5rem;
    margin-left: 1rem;
    width: 12rem;
    
}
.devisForm .commande > div{
    background-color: var(--green-rounded-form-div);
    width: fit-content;
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
}
.devisForm .commande > div >input{
    margin-right: 0.5rem;
}

.devisForm .files{
    background-color: rgba(125,65,27,0.3);
    padding: 1rem;
    grid-area: f;
}
.devisForm .files >div {
    display: block;
}
.devisForm .files >div >div {
    background-color: rgba(107,167,82,0.7);
    border-radius: 5rem;
    padding: 1rem 3rem;
    width: 26rem;
}
.devisForm .files ul{
    background-color: rgba(0,0,0,0.1);
    width: 27rem;
    padding: .5rem;
    border-radius: 1rem;
    text-align: right;
}
.devisForm .files:before{
    content: url("../../images/clipper.png");
}
.devisForm .document:before{
   content: url("../../images/newdoc.png");
}

.devisForm .machineries:before{
    content: url("../../images/nacelle.png");
}
.devisForm .devisData:before{
    content: url("../../images/icon_import.png");
}
.devisForm .comments:before{
    content: url("../../images/comment.png");
}
.devisForm .commande:before{
    content: url("../../images/new-devis.png");
}

@media screen and (max-width:1200px) {
    .devisForm{
        display: inline-block;
        
    }
    .devisForm > div{
        
        grid-gap: 1rem;
    }
    
 
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    .blurred-container {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
    }
  }



}


