.pin {

  border-radius: 50% 50% 50% 0;
  transform: rotate(-45deg);
  left: -25px;
  top: -25px;
  display: flex;
  border: #dddddd solid 1px;
  cursor: pointer;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
  }

.pin.square {
  border-radius: .2rem;

}

.pin.triangle{
  border-radius: 0;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  transform: rotate(-45deg);
}

.pin.bounce.triangle span{
  transform: rotate(0deg);
  position: absolute;
  font-size: .5rem;
  top: 1rem;
  left: .1rem;
  background-color: rgba(0, 0, 0, 0.42);
}

.myPin .pin span{
  display: block;
  text-align: center;
  font-size: .55rem;
  color: rgba(255, 255, 255, 0.92);
  transform: rotate(45deg);
  margin: auto;
}

.myPin:hover{
  transition: .5s all ease-in-out;
  transform: scale(1.25);
  position: absolute;
  z-index: 10;
  transition-delay: .5s;
  transition-duration: 1s;
}

.map .MuiIconButton-label{
  color: white;
  font-weight: bold;
  width: 2rem;
}

.map .MuiPaper-root{
  background-color: rgba(255, 255, 255, 0.86);
  padding: .5rem;
  border-radius: .5rem;
  height: 95%;
}

.map .MuiPaper-root > input::placeholder {
  color: white;
  font-weight: 500;
  font-size: 1rem;
}

.map .MuiPaper-root > input{
  font-family: Rubik, sans-serif;
  background-color: var(--interv-thead);
  padding-left: .5rem;
  height: 2rem;
  margin: .2rem;
  border-radius: .5rem;
  border: 1px solid rgba(236, 236, 236, 0.56);
}

.pin.square span {
  transform: rotate(0deg);
}

.myPin.cluster .pin span{
  color: rgb(255, 255, 255);
  font-size: .9rem;
  font-weight: 700;
  background-color: rgba(0, 0, 0, 0.38);
  border-radius: 2rem;
  padding: .2rem;
  border: 1px dashed rgba(0, 0, 0, 0.4);
}
.myPin.nocluster .pin span{
  color: rgb(255, 255, 255);
  font-size: .7rem;
  font-weight: 500;
  background-color: rgba(0, 0, 0, 0.38);
  border-radius: 2rem;
  padding: .2rem;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.myPin.cluster .pin:hover span{
  border: white 1px solid;
  transition: all .3s ease;

}


.pin:hover{
  filter: brightness(130%);
  cursor: pointer;
  border: white 1px solid;
  background-color: rgba(0, 0, 0, 0.65);
  border-radius: .3rem;
  transition: all .3s ease;
  color: rgb(255, 255, 255);
  font-size: 2rem;
  font-weight: bold;
  transform: scale(125) rotate(-45deg);
  z-index: 2;
}

.myPin{
  transition: all .3s ease-in-out;
  transition-delay: 1s;
  transition-duration: 1s;
}

.myPin .child{
  position: absolute;
  /*left: -10px*/
  top: .5rem;
  visibility: hidden;
  transition: visibility 300ms ease-in-out;

}
.myPin .child:hover{
  visibility: visible;
  z-index: -1;
}
.myPin .child.force{
  visibility: visible;
}
.pin:hover +.child{
  visibility: visible;
  z-index: -1;
}


.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
  }
.bounce.square , .bounce.triangle{
  animation-name: bounce-square;
  animation-fill-mode: both;
  animation-duration: 1s;
}
.bounce-shadow{
  animation-name: bounce-shadow;
  animation-fill-mode: both;
  animation-duration: 1s;

}


@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(0deg);
  }

  60% {
    opacity: 1;
    transform: translateY(0px) rotate(0deg);
  }

  80% {
    transform: translateY(0px) rotate(-60deg);
  }

  90% {
    transform: translateY(0px) rotate(-20deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

@keyframes bounce-square {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(0deg);
  }

  60% {
    opacity: 1;
    transform: translateY(0px) rotate(0deg);
  }

  80% {
    transform: translateY(0px) rotate(-60deg);
  }

  90% {
    transform: translateY(0px) rotate(-20deg);
  }

  100% {
    transform: translateY(0) rotate(0deg);
  }
}


@keyframes bounce-shadow {
  0% {
    opacity: 0;
  }

  60% {
    opacity: .3;
  }

  80% {
    transform: translateX(-5px) ;
  }

  90% {
    transform: translate(5px) ;
  }

  100% {
    transform: translateX(0) ;
  }
}

