
.glow {
    border: solid 1.5px var(--glow-border);
    background: var(--glow);
    box-shadow: rgba(0, 0, 0, 0.37) 0 10px 25px;
    border-radius: .5rem;
    transition: all ease-in-out .2s;
}
.row{
    max-height: 3rem;
    transition: all ease-in-out .8s;
}

.row > td a#id {
    font-size: larger;
}
.row > td a#id:hover{
    font-size: larger;
    font-weight: bold;
    text-decoration: underline;
    transition: text-decoration 300ms;
}

.row td {
    border-radius: .3rem;
}

.row tr {
    text-align: center;
    width: 90%;
    height: fit-content;
}
.row td#id:hover {
    background: rgba(255, 0, 0, 0);
    border: 1px solid transparent;
}
.row > td a#id:hover::after {
    content: "Afficher l'intervention";
    color: white;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.7);
    top: 4rem;
    padding: .3rem;
    font-size: .7rem;
    font-weight: 500;
    border: 1px solid white;
    border-radius: .2rem;
    position: absolute;
    text-shadow: none;
    z-index: 1;
    animation: transition2 1s linear;
}

.row td{
    position: relative;
    text-align: center;
    text-overflow: ellipsis;
    cursor: default;
}
.row span{
    /*position: absolute;*/
    border-radius: 50% ;
    font-size: .6rem;
    color: rgba(255, 0, 0, 0.699);
    padding:0 0.15rem;
    background-color: rgba(255, 0, 0, 0.705);
    box-shadow: rgba(0,0,0,0.2) 2px 1px 5px;
}

.row:hover{
    border-radius: 3px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.27), rgba(255, 255, 255, 0));
    background-size: 200% 200%;
    box-shadow: rgba(255, 255, 255, 0.32) 0 0 35px;
    text-shadow: var(--highlighted-text-shadow);
    min-width:15px;
    transition: all .2s ease-in-out;
    animation: wave 2.5s ease-in-out infinite;
}

.row:hover td{
    border-style: double;
    border-color: var(--editable-border);
    background-color: var(--td-cell-hover);
}
.row .editable{
    transition: all .5s ease-in-out;
    border: var(--editable-border) 1px solid;
    border-radius: .3rem;
}

.row .editable:hover {
    box-shadow: rgba(0, 0, 0, 0.54) 0 3px 10px;
    background-color: rgba(255, 255, 255, 0.63);
    border-radius: .4rem;
    animation: box-shadow-animate 1s infinite;
    border: rgb(255, 255, 255) 1px solid;
    cursor: pointer;
    transition: all 0s ease-in-out;
}


.editable:hover:after{
    content: "Ouvrir / éditer";
    color: white;
    background-color: rgba(0, 0, 0, 0.7);
    top: 4.3rem;
    padding: .3rem;
    font-size: .7rem;
    font-weight: 500;
    border: 1px solid white;
    white-space: nowrap;
    border-radius: .2rem;
    position: absolute;
    z-index: 15;
    animation: transition2 .7s linear;
}

.row td:hover{
    border: 1px solid var(--editable-border-hover);
    background-color: var(--td-cell-hover);
    transition: all 0s;
}

.row td{
    background-color: rgba(255, 255, 255, 0);
    transition: all .4s ease-in-out;
    border: 1px solid var(--td-cell);
}

@keyframes transition2 {
    from {  opacity:0; }
    70% { opacity: 2% }
    to {   opacity:1;  }
}

@keyframes wave {

    0%{background-position:200% 0}
    100%{background-position:0 50%}
}

@keyframes box-shadow-animate {
    0% {
        box-shadow: 0 0 0 0 var(--text);
    }

    50% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}


@keyframes pulse-white {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.52);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }

}



