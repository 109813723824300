.timeEstimation{
    margin: .5rem;
    padding: .5rem;
}

.timeEstimation input{
    font-size: 1.3rem;
    height: 2rem;
    border-radius: .2rem;
font-family: Rubik;
    text-align: center;
    width: 5rem;
    border: 1px solid var(--input-border-color);
    background-color: var(--input-color);
    display: block;
   margin: .2rem;
}

.timeEstimation div{
    background-color: var(--time-estimation-div);
    border-radius: .7rem;
    display: inline-block;
    padding: .3rem .2rem;
    margin: .1rem;
}
.timeEstimation h2{
    margin-left: .8rem;
}

.timeEstimation .img-container{
    background-color: transparent;
    display: inline-block;
    transform: translateY(20px);
}



