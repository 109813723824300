.Map{
    
}

.span{

}

.map .LoadingSVG{
    position: absolute;
    z-index: 10;
    width: 70%;
    padding: 163% 11%;
    margin: auto;
    border-radius: 1rem;
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.16);
}