.stateLevel div,.stateLevel ul,.stateLevel li {
    display: inline-block;
    white-space: nowrap;
    z-index: 2;
}
.stateLevel:hover {
    background-color: rgba(255, 255, 255, 0.33);
    border-radius: 3rem;
    cursor: pointer;

}
.operation-row ul{
    list-style: none;
}

.stateLevel{
    position: relative;
    width: fit-content;
    margin: auto;
}

.stateLevel ul{
    padding: .1rem;
    background-color: rgba(103, 103, 103, 0.4);
    border: 1px rgba(255, 255, 255, 0.68) solid;
    border-radius: 2rem;
    left: -5.9rem;
    top: .5rem;
    position: absolute;
}
.stateLevel ul > li{
    border: 2px solid rgba(255, 255, 255, 0);
    margin: .07rem;

}

.stateLevel ul > li:hover{
    border: 2px solid white;

}