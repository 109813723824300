.intervention {
    display: grid;
    grid-template-areas: "m  x x x x x z "
                         "m  g g g g g z "
                         "m  c c c p p z "
                         "m  d d s p p z "
                         "m  d d s p p z "
                         "m  o o s p p z "
                         "m  i i q q q z "
                         "m  r r r r r z "
                         "m  r r r r r z "
                         "m  v v v v v z "
                         "m  a a a a a z ";
    grid-gap: 0.5rem;
    height: 93vh;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: auto;
}

.intervention table{
    border-collapse: separate;
    border-spacing: 1px;
    border: solid var(--editable-border) 1px;
    border-radius: .7rem .7rem .3rem .3rem;
    width: 100%;
    padding: .2rem .1rem;
}

.intervention td,.intervention th{
    border: solid 1px var(--ul);
    padding: 0.2rem;
    font-size: 1rem;
    border-radius: .5rem .5rem 0 0;
    background-color: var(--modal-background);
    color: var(--interv);
}
.intervention th{
    background-color: rgba(255, 255, 255, 0.062);
}
.intervention td{
    border-radius: 0 0 0 0;
}

.intervention .generale{
    grid-area: g ;
}

.intervention .stepper{
    background-color: rgba(255, 255, 255, 0.27);
    border-radius: 1rem;
    border: solid 1px white;
    width: 97%;
    grid-area: x;
    opacity: .85;
    cursor: default;
}
.intervention .MuiStepper-root {
    padding: 1.5rem .8rem .3rem .8rem;
    margin: auto;
}

.intervention .MuiStepLabel-label{
    font-family: Rubik;
    font-size: 0.8rem;
    color: rgba(95, 28, 28, 0.5);
}

.intervention .MuiStepIcon-text {
    fill: rgb(255, 255, 255);
    font-family: Rubik;
}


.intervention .MuiStepIcon-root.MuiStepIcon-completed {
    color: #294d3a;
    opacity: .5;
}

.intervention .MuiStepLabel-active{
    font-size: 1.1rem;
    border: 1px solid white;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.62);
    text-decoration-line: underline;

}

.intervention .MuiStepIcon-root.MuiStepIcon-active {
    transform: scale(1.5);
    color: #52b52a;
}
.intervention .client{
    grid-area: c;
}

.intervention .documents{
    grid-area: d ;
    display: table-cell;
    padding: 0 1rem;
    border-radius: 1rem;
    background-color: #FFFFFF54;
    border: solid 1px var(--div-border);
}

.intervention .documents h2{
    padding: .2rem;
}
.intervention .documents .documentLoaderList{
    background-color: rgba(245, 245, 245, 0.24);
    border-radius: .5rem;
    border: 1px solid rgba(255, 255, 255, 0.7);
    padding: .3rem;
    width: 90%;
    min-height: 4.5rem;
    margin: auto;

}

.intervention .documents .documentLoaderList img{
    opacity: 1;
    transition: all .4s ease-in-out;
}

.intervention .documents .documentLoaderList img:hover {
    cursor: pointer;
    opacity: .5;
    transition: all .3s ease-in-out;
}


.intervention .plan{
    grid-area: p;
    border-radius: 1rem;
    padding: 2%;
    background-color: #FFFFFF54;
    border: solid 1px var(--div-border);
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.intervention .plan .icon{
    margin-left: 0;
    border-radius: 0;
    border: white 0px solid;

}

.intervention .plan img:not(.icon){
    width: 100%;
    height: fit-content;
    border-radius: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0);
    opacity: .9;
    transition: .5s ease-in-out all;
}
.intervention .plan img:hover:not(.icon){
    border: 1px solid white;
    opacity: 1;
    transition: .3s ease-in-out all;
    box-shadow: rgba(0,0,0,0.4) 0 3px 10px;
}

.intervention .margin img{
    display: block;
}

.intervention .rapport{
    margin: 1rem auto;
    grid-area: r;
    width: 100%;
}

.intervention .rapport table{
    overflow: scroll;
}

.intervention .rapport table th{
    width: fit-content;
}

.intervention .addedOperation{
    border: solid 1px var(--div-border);
    background-color: rgba(255, 255, 255, 0.2);
    padding: .5rem;
    border-radius: 1rem;
    grid-area: i;
}

.intervention .signature{
    display: table-cell;
    padding: .5rem .9rem;
    min-width: fit-content;
    border-radius: 1rem;
    background-color: var(--modal-background);
    border: solid 1px var(--div-border);
    grid-area: s;
}

.intervention .signature > *{
    border-radius: .5rem;
}

.intervention .signature > img{
    opacity: .5;
    transition: all .3s ease-in-out;
    height: 20rem;
    width: 100%;
    display: block;
    margin: auto;
    object-fit: cover;
    border: 1px solid rgba(0, 0, 0, 0.32);
    border-radius: 1rem;
}

.intervention .signature > img:hover{
    opacity: 1;
    transition: all .3s ease-in-out;

}


.intervention .object{
    display: inline-block;
    border-radius: 1rem;
    background-color: #FFFFFF54;
    padding: 0 1rem ;
    border: solid 1px var(--div-border);
    grid-area: o;
}
.intervention .object input:hover{
    border: white 1px solid;
}

.intervention .object input{
    width: 100%;
    font-family: rubik;
    font-size: 1rem;
    font-weight: 500;
    box-sizing: border-box;
    padding: 0.5rem 1rem .5rem 1rem;
    border-radius: 0.3rem;
    margin-bottom: .5rem;
    border: solid 1px var(--div-border);
    background-color: rgba(255, 255, 255, 0.5);
}
.intervention .resume{
    border-radius: 1rem;
    background-color: #FFFFFF54;
    padding: 0 1.4rem 1rem 1.4rem;

    border: solid 1px var(--div-border);
    grid-area: q;
}

.intervention .resume textarea{
    padding: .7rem;
    font-size: .9rem;
    font-weight: 500;
    font-family: rubik;
    border-radius: 0.5rem;
    box-sizing: border-box;
    min-width: 67vw;
    max-width: 67vw;
    max-height: 25rem;
    width: 100%;
    min-height: 8rem;
    border: solid 1px var(--div-border);
    background-color: rgba(255, 255, 255, 0.5);
}
.intervention .resume textarea:hover{
    border: white 1px solid;
}

.intervention .margin{
    padding: 5px ;
    grid-area: m;
}

.intervention .conclusion{
    border-radius: 1rem;
    background-color: #FFFFFF54;
    padding: 0 1rem 1rem;
    border: solid 1px var(--div-border);
    width: 98%;
    display: block;
    grid-area: v;
}

.intervention .conclusion textarea:hover {
    border: 1px solid white;
}

.intervention .conclusion textarea{
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, 0.8);
    font-family: rubik;
    font-size: .9rem;
    width: 85vw;
    min-width: 85vw;
    max-width: 85vw;
    min-height: 7rem;
    max-height: 32rem;
    float: right;
    height: 8rem;
    padding: .4rem .9rem;
    border-radius: 0.3rem;
    border: solid 1px var(--div-border);
    opacity: .7;
}
.intervention .margin > div{
    top: 8rem;
    border-radius: 5rem;
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.178) 2px 0 15px ;
    width: fit-content;
}
.intervention .margin > div > *{
    display: block;
}
.intervention .margin > .illus{
    opacity: 0.1;
}

.intervention .risk-analysis{
    background-color: rgba(117, 117, 117, 0.34);
    border: solid 1px rgba(155, 155, 155, 0.42);
    font-size: .9rem;
    width: 98%;
    margin-bottom: 2rem;
    padding: 1rem;
    grid-area: a;
    border-radius: 1rem;
    display: block;
}



.intervention .risk-analysis div#picture{
    margin: auto;
    width: border-box;
}

.intervention .risk-analysis >div{
    margin: auto;
    background-color: rgba(245, 245, 245, 0.14);
    border-radius: .7rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, 11rem);
    grid-gap: 0.3rem;
    max-height: 26rem;
    overflow-y: auto;
    justify-content: center;
}

.intervention .risk-analysis .card{
    background-color: #FFFFFF54;
    border: solid 1px #6969696b;
    border-radius: .5rem;
    padding: .5rem .5rem 0 0;
    text-align: left;
    height: 20rem;
    overflow-y: auto;
}

.intervention .risk-analysis > div img{
    margin:1rem 0 .5rem .5rem;
    border-radius: .5rem;
    max-width: 9rem;
}

.intervention .select-operation{
    width: 30rem;
    display: inline-block;
}

.intervention .status:hover:before{
    content: "Définir l'état à Rédaction / Fait";
    color: white;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.7);
    top: 2rem;
    right: 3rem;
    padding: .3rem;
    font-size: .7rem;
    font-weight: 500;
    border: 1px solid white;
    border-radius: .2rem;
    position: absolute;
    text-shadow: none;
    z-index: 1;
    animation: transition2 .5s linear;
}

@keyframes transition2 {
    from {  opacity:0; }
    70% { opacity: 2% }
    to {   opacity:1;  }
}

.intervention .status{
    position: fixed;
    right: 1rem;
    top: 4rem;
}


.intervention td:hover{
    border: 1px solid white;
}

.intervention td.clikable{
    cursor: pointer;
}
.intervention td.clikable:hover{
    border: 1px solid white;
    box-shadow: rgba(0, 0, 0, 0.384) 0 1px 10px;
}

