.caroussel{
    display: flex;
    max-width: 80vw;
    flex-direction: row;
    align-items: center;
    border-radius: 1.3rem;
    background-color: rgba(0, 0, 0, 0.301);
    border: 1px solid rgba(0, 0, 0, 0.185);

}
.caroussel-container{
    display: inline-grid;
    border: 1px solid rgb(255, 255, 255);
    background-color: rgba(255, 255, 255, 0.37);
    padding: .3rem;
    border-radius: 2rem;
}
.indexNumber{
    color: white;
    font-size: 1.5rem;
    margin: auto;
    border: white 1px solid;
    text-align: center;
    background-color: rgba(115, 114, 114, 0.43);
    padding: .5rem 2rem;
    width: fit-content;
    border-radius: 1rem;
    }

.caroussel-container select button{
    padding: 5rem;
    background-color: yellow;
}

.caroussel .MuiIconButton-label{
    font-size: 4rem;
    font-family: rubik;
    text-align: center;
    position: relative;
    color: white;
    margin: .5rem;
    padding: 200% 1.5rem;
    border: 2px solid rgba(255, 255, 255, 0.432);
    border-radius: 4rem;
    background-color: rgba(105, 105, 105, 0.144);
}

.caroussel >img{
    width: 25vw;
    border-radius: 1rem;
    border: 1px solid whitesmoke;
    max-height: 100vh;
    transition: all 1s ease-in-out;
    cursor: zoom-in;
    z-index: 5;
    margin: .5rem;
}

.caroussel >img:active{
    width: 60vw;
    max-height: 150vh;
    overflow: scroll;
    -webkit-transform: scale(.9);
    transform: scale(.9);
    cursor: zoom-in;
    transition-delay: 0s;
    z-index: 5;

}

.caroussel >img:hover{
    width: 60vw;
    max-height: 150vh;
    margin: 0;
    overflow: scroll;
    -webkit-transform: scale(.9);
    transform: scale(.9);
    transition-delay: 1s;
    cursor: zoom-in;
    z-index: 5;

}
