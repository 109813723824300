
.loadingTable td{
    border-top: 0px solid rgba(229, 229, 229, 0.2);
    border-bottom: 1px solid rgba(229, 229, 229, 0.27);
    padding: .5rem .4rem;
    transition: all 1s ease-in-out;
    vertical-align: top;
    /*animation: borderwaves 3s infinite;*/
}
.loadingTable span{
    /*width: calc(200% - 5px);*/
    height: 1rem;
    border-radius: 3px;
    background: var(--loading-gradient);
    background-size: 800% 800%;
    display: inline-block;
    min-width:15px;
    animation: wave 3s ease infinite;
}
.loadingTable .loading-svg{
    margin: 15vh 35vw;
    position: absolute;
    opacity: 70%;
}

@keyframes borderwaves{
    0%{border: 1px solid transparent}
    40%{border: 1px dashed rgba(255, 255, 255, 0.1) }
    70%{border: 1px dashed rgba(255, 255, 255, 0.3) }
    100%{border: 1px solid rgba(255, 255, 255, .1) }
    }

@-webkit-keyframes wave {
    0%{background-position:0% 50%}
    20%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes wave {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes wave {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}


.loadingTable span.sub-temp{
    width: 4rem;
    height: 1rem;
}
.loadingTable span.sub-temp-three{
    width:calc(100% - 70%);
    display:block
}

.loadingTable tr{
    max-height: 1rem;
}




@keyframes shine-lines-table {
    0% {
        opacity:0.4;
    }
    40%{
        opacity:0.8;
    }
    100% {
        opacity:0.4;
    }
}