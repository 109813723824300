.mapPointer{
    position: relative;
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0, 0, 0, 0.274) 100%);
    padding: 2rem;
    border-radius: 2rem;
    margin-bottom: 1rem;
    text-align: center
}

.mapPointer:hover{
    position: relative;
    z-index: 2;
}

.mapPointer .search-suggest:before{
    display: inline-block;
    content: url(../../images/add-site.png);
    padding: .3rem;
    margin: 0rem 1rem;
}

.mapPointer .search-suggest{
    padding: .5rem;
    background-color: rgba(51, 90, 206, 0.432);
    border: rgba(34, 57, 105, 0.78) 1px solid;
    border-radius: 1.3rem;
    color: white;
}

.mapPointer .tools{
    width: 100%;
    justify-content: space-between;
    /*margin-left: 35rem;*/
    display: inline-flex;
    align-items: center;
}
.mapPointer .search-area >* {
    display: inline-block;
    margin:.2rem;
    }

.mapPointer .map .gm-style{
    color: rgba(0, 0, 0, 0);
    cursor: pointer;
}
.mapPointer .search-area{
    background-color: rgba(255, 255, 255, 0.36);
    border: 1px solid white;
    border-radius: 2rem;
    padding: .2rem .7rem;
    margin: .2rem;
}

.mapPointer .search-area .select-search{
    width: 80%;
}

.mapPointer .search-area .search-suggest{
    display: block;
    margin-bottom: .2rem;
    padding: .1rem;
}


.mapPointer .tools .picture{
    padding-right: 5rem;
    margin-top: .5rem;
}

.mapPointer .screenshot{
    display: flex;
    transition: all 0.5s ease-in-out;
    z-index: -2;
    border: solid 2px  rgba(255, 255, 255, 0.5);
    padding: 0.1rem 3rem;
    border-radius: 3rem ;
}

.mapPointer .front,.mapPointer .front img{
    max-height: 72px;
    max-width: 200px;
    bottom: 0;
    right: 0;
    z-index: 1;
}

.mapPointer .map {
    width: 844px;
    height: 400px;
    margin: auto;

}