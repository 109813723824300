.contactForm {
    display: inline-flexbox;
    grid-gap: 2px;
    grid-template-columns: 1fr 1fr;
    border: 1px solid rgba(0, 0, 0, 0.253);
    padding: 1rem;
    border-radius: 1rem;
    margin: auto;
}

.contactForm input {
    margin: .3rem;
    width: 40vw;
    background-position: 14px 12px;
    background-repeat: no-repeat;
    font-size: 16px;
    padding: 14px 20px 12px 45px;
    border: none;
    border-bottom: 1px solid #ddd;
    font-family: rubik;
    border-radius: .5rem;

}

#status{
    grid-column: 1 / span 2;
}