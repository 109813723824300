.eventCreator:before{
content: url("./../images/planning-icon.png")
}

.eventCreator{
    margin: auto;
    text-align: center;
    display: grid;

}
.eventCreator{
margin: 2rem;
}

.eventCreator > input{
    padding: .5rem;
    margin: .3rem;
    border-radius: .3rem;
    border: 1px solid rgba(0, 0, 0, 0.44);
}