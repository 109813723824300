.work-force{
    background-color: rgba(5, 53, 52, 0.2);
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.21);
    border: rgba(0, 0, 0, 0.4) 1px solid;
    border-radius: 1rem;
    padding: .3rem 1rem;
    min-height: fit-content;
}

.work-force:before{
    content: url("./../images/bullet-icon.png") " Opération";
}

.work-force .product{
    margin: .2rem;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    width: fit-content;
}

.work-force .duration:before{
    content: url("./../images/duration.png") " Durée estimée : ";
    margin: .2rem;
    color: white;
}

.work-force .duration{
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: .7rem;
    padding: .6rem 6.5rem .3rem .9rem;
    margin: 1rem;
}
