.tabs-interventions {
    display: grid;
    grid-template-columns: 1fr 15fr;
    grid-template-rows: fit-content(100%) 15fr;
    grid-gap: .05rem;
    z-index: 0;

}
.tabs-interventions a{
    display:block;
}

.tabs-interventions .table-wrapper {
    position: relative;
    overflow: hidden;
    grid-column: 2;
    grid-row: 1 /3;
}
.tabs-interventions .LoadingSVG{
    margin: 15vh 35vw;
    position: absolute;
    opacity: 70%;
}

.tabs-interventions table{
    font-size: 13px;
    font-weight: var(--tab-font-weight);
    width: 100%;
}
.tabs-interventions table a{
    font-weight: var(--tab-font-weight);
}
.tabs-interventions .table-scroll {
    height: 93vh;
    overflow: auto;
}
.tabs-interventions .scroll{
    height: 98%;
    width: 1rem;
    background: rgba(255, 255, 255, 0.11);
    box-shadow: rgba(255, 255, 255, 0.18) 10px 0 30px 2px;
    position: absolute;
    border-radius: 2rem;
    transition: all .7s ease-in-out;
    z-index: 10;
}
.tabs-interventions .scroll:hover{
    background: rgba(255, 255, 255, 0.33);
    box-shadow: rgba(255, 255, 255, 0.52) 10px 0 40px 10px;
    z-index: 2;
    transition: all .4s ease;
}

.tabs-interventions .scroll#right{
    right: 0;
    z-index: 2;
    transform: rotate(180deg);
}


.tabs-interventions tbody {
    color: var(--tab-text);
    font-family: 'Rubik', 'sans-serif';
    max-width: 100%;
}

.tabs-interventions tfoot tr{
    column-span: 2;
}

.tabs-interventions .tab-inside{
    height: 100vh;
}

.tabs-interventions thead {

    font-family: 'Nunito', 'sans-serif';
}
.tabs-interventions th {
    background-color: var(--interv-thead);



}
.tabs-interventions th .filter-group{
    border: 1px rgba(255, 255, 255, 0) solid;
    transition: all ease-in-out .4s;
    height: 2.5rem;
}

.tabs-interventions th .filter-group:hover{
    border: 1px rgba(255, 255, 255, 0.49) solid;
    border-radius: .5rem;
    transition: all ease-in-out .2s;
    background-color: rgba(255, 255, 255, 0.14);
}

.tabs-interventions thead th {
    position: sticky;
    top: 0;
    border-radius: 10px;
    z-index: 3;
}

.tabs-interventions thead tr {
    border-radius: 10px;
}


.map {
    width: 90vw;
    height: 80vh;
    padding: .2rem;
    border-radius: .5rem;
}

.map .planning-card{
    color: black;
    background: rgba(255, 255, 255, 0.856);
    border-radius: .5rem;
    padding: .2rem;
    min-width: 13rem;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
    width: fit-content;
    font-family: rubik, sans-serif;
    font-size: .8rem;
    cursor: default;
}

.map .planning-card div{
    margin: .3rem;
    background-color: rgba(87, 120, 170, 0.123);
    padding: .2rem;
    border-radius: .5rem;
    max-height: 20rem;
    overflow: auto;
}

.map .planning-card ul{
    background-color: rgba(238, 255, 0, 0.363);
    font-weight: 900;
    cursor: pointer;
}
.map .planning-card a{
    color: black;
}


.map .MuiIconButton-root{
    border: 1px solid white;
    display: block;
    background-color:var(--interv-thead);
    padding: .5rem 1rem;
    border-radius: .8rem;
    margin: .5rem;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.3);
    transition: all .3s ease-in-out;
}

.map .planning-card .intervention-type{
    color: white;
}

.map .planning-card ul::before {
    content: url(../images/contact-interaction.png);
}


.criteria-bar{
    position: sticky;
    margin: auto;
    margin-top:.5rem;
    top: 65px;
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.2);
    background-color: transparent;
    border-radius: 5rem;
    height: 40vh;
    width: min-content;
    text-align: center;
    transition: all 1s ease-in-out;
}

.loading {
    position: fixed;
    display: block;
    top: 50%;
    left: 50%;
    opacity: .3;
}
.no-result{
    color: var(--no-result);
    opacity: 60%;
    position: absolute;
    font-size: 4rem;
    text-align: right;
    text-shadow: var(--no-result) 0 0 7px;
    animation: vibrate-light 10s infinite;
    margin: 10%;
}
@keyframes vibrate-light {
    from {  opacity: 0;}
    50% { opacity: 50%;}
    to {     opacity: 0;}
}


.no-result:before{
    font-size: 8rem;
    text-align: center;
    content: '😕';
    display: block;
    animation: vibrate-light 3s infinite;
}

.criteria-bar .add-map button:disabled {
    opacity: 0.3;
    filter: grayscale(100%);
}

.criteria-list-dropdown {
    grid-row: 1;
    grid-column: 1;
    background-color: var(--interv-thead);
    border-radius: .7rem;
    height: 2.9rem;
    position: relative;
    margin-left: .5rem;

}
.criteria-list-dropdown .criteria-icon {
    margin: .4rem .5rem .6rem 2rem;
    top: 3px;
}

.criteria-list-dropdown .criteria-icon:active {
    transform: scale(.95);
}


.criteria-list-dropdown ul.criteria-list {
    padding: 2px;
    display: none;
    margin: 1px;
    border-top: none;
}

.criteria-list-dropdown ul.criteria-list li {
    list-style: none;
}

.criteria-list-dropdown.visible .criteria-list {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    position: absolute;
    padding: 8px;
    width: 165px;
    top: 50px;
    background-color: rgba(255,255,255,0.2);
    backdrop-filter: blur(15px);
    border-radius: 8px;
    z-index: 2;
    font-family: Nunito, sans-serif;
}
@-moz-document url-prefix(){
    .criteria-list-dropdown.visible .criteria-list {
        background-color: rgba(255, 255, 255, 0.8);

    }
}


.criteria input[type=swi] {
    margin: 5px;
    transform: scale(1.5);
}

.filter-label:hover{
    color: black;
}

.filter-label {
    font-size: 1em;
    color: rgba(255, 255, 255, 0.8);
    display: block;
    opacity: 1;
    width: 100%;
    cursor: pointer;
    transform: translateY(-1.25em);
    transform-origin: 0 0;
    transition: all .3s ease-in-out;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);

}

.label{
    font-size: 1em;
    color: rgba(255, 255, 255, 0.66);
    width: 100%;
    display: block;
    transform: translateY(-.1em);
}

.filter-input{
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 0;
    font-family: Rubik, sans-serif;
    border-color: transparent;
    border-style: none none solid none;
    max-width: 5rem;
    height: 0.5rem;
    transition: all ease-in-out .2s;
    color: var(--text);
    font-size: .9rem;
    font-weight: 500;
    margin-top: .3rem;
}

@media screen and (max-width:1600px){
    .filter-input{
        max-width:4rem;

    }
}

@media screen and (max-width:1480px){
    .filter-input{
        max-width:3rem;
    }
}

.filter-input::placeholder {
    color: transparent;
}

.filter-input:focus {
    box-shadow: none;
    outline: none;
    border-color: #cccccc;
}

.filter-input:focus + .filter-label,
.filter-input:not(:placeholder-shown) + .filter-label {
    font-size: 1em;
    color: rgba(255, 255, 255, 0.85);
    transform: translateY(0em);
}

.filter-input:focus + .filter-label,
.filter-input:not(:placeholder-shown) + .filter-label:hover {
    color: black;

}
.filter-group:focus-within {
    transform: scale(1.05, 1.05);
    background-color: rgba(255, 255, 255, 0.49);
    border-radius: .5rem;
}

tr:nth-child(even) {
    background-color: var(--interv-bg-even);
}
.pager{
    border: solid 1px var(--pager-border);
    width: fit-content;
    font-weight: 600;
    font-size: .9rem;
    padding: .1rem .8rem;
    border-radius: 2rem;
    margin: 1rem auto;
    color: var(--pager-border);

}

.pager .select#pageNumber{
    font-family: rubik, sans-serif;
    height: .8rem;
}
.pager select{
    padding: .05rem;
    filter: var(--invert);
    background-color: rgba(255, 255, 255, 0.342);
}

.pager *{
    display: inline-block;
    margin: .1rem;
}

.pager img{
    filter: var(--invert)
}
