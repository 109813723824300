@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@600&display=swap');


.App {
    text-align: center;
}

body {
    background-position: bottom 0 center;
    background-size: contain,cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* background-color: rgba(15, 45, 45, 1); */
    background-image: var(--background-image), var(--background-gradient);
    cursor: default;
}

::selection{
    background-color: rgba(255, 255, 0, 0.5);
    color: #e50000;
    border-radius: .5rem;
}
body span{
    font-family: Rubik, sans-serif;
}

body p a{
    opacity: 1;
    transition: opacity 600ms ease-in-out;
}

.client-page Stats{
    background-color: rgba(255, 0, 0, 0.34);
}

body p:hover{
    opacity: 1;
    transition: opacity 100ms ease-in-out;
}

body a{
    color: var(--link);
    font-weight: bold;
    text-decoration: underline 0.15em rgba(255, 255, 255, 0);
    transition: text-decoration-color 300ms;
    opacity: .9;

}

body a:hover{
    opacity: 1;
    transition: text-decoration .4s ease-in-out;
    text-decoration-color: var(--link)
}

h1{
    font-size: 1.7rem;
    font-family: Rubik, sans-serif;
}

h2{
    color: #000000;
    font-size: 1.1rem;
    display: flex;
    width: fit-content;
    align-items: center;
    font-family: Rubik, sans-serif;
}

h3{
    font-family: Rubik, sans-serif;
    font-size: 1.2rem;
}

body h1 img,body h2 img,body h3 img{
    margin: 0 .5rem 0 0;
    display: inline;
    vertical-align: top;
}

.myBody {
    opacity: 1;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.map {
    width: 100%;
    height: 100%;
}

.navActive img{
    opacity: 1;
}

.input:hover{
    border: 1px solid white;
}

.MuiIconButton-root:enabled {
    padding: 0;
}

.MuiIconButton-root:disabled {
    padding: 0;
}

.MuiIconButton-root:active{
    animation: pulse-white .5s ease;
}

@keyframes pulse-white {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.52);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.MuiIconButton-root{
    font-family: Nunito, serif;
}

.MuiIconButton-root.validation-button {
    background: #359f00;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23);
    padding: .2rem 4.2rem;
    margin: .3rem auto;
    border-radius: 2rem;
    font-size: 1.2rem;
    border: rgba(26, 194, 0, 0.75) 1px solid;
    color: rgb(255, 255, 255);
    transition: all .4s ease-in-out;
}


.MuiIconButton-root.refresh-button {
    background: #2a43d3;
     box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23);
    padding: .3rem 3.5rem;
    margin: .3rem;
    border-radius: 2rem;
    font-size: 1.2rem;
    border: rgba(0, 187, 255, 0.75) 1px solid;
    color: rgb(255, 255, 255);
    transition: all .4s ease-in-out;
}

.MuiIconButton-root.validation-button:before {
    transform: scale(.7);
    margin: 0 .5rem 0 -.7rem;
}
.MuiIconButton-root.refresh-button:hover:after{
    content: "Rafraîchir";
    color: white;
    white-space: nowrap;
    background-color: rgba(0, 0, 0, 0.7);
    top: 2rem;
    right: 3rem;
    padding: .3rem;
    font-size: .7rem;
    font-weight: 500;
    border: 1px solid white;
    border-radius: .2rem;
    position: absolute;
    text-shadow: none;
    z-index: 1;
    animation: transition2 .5s linear;
}

.MuiIconButton-root.refresh-button:before {
    content: url('./images/refresh-icon.png');
    margin: 0 .5rem 0 -.9rem;
    transform: scale(.7);
}
.MuiIconButton-root.traveltime-button:before {
    content: url('./images/travel-time.png');
    margin: 0 .3rem;
}
.MuiIconButton-root.traveltime-button {
    background: #ada500;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.23);
    padding: .3rem 3.5rem;
    margin: .3rem;
    border-radius: 2rem;
    font-size: 1.2rem;
    border: rgba(255, 248, 0, 0.6) 1px solid;
    color: rgb(255, 255, 255);
    transition: all .4s ease-in-out;
}

.MuiIconButton-root.validation-button:hover {
    background: rgba(0, 175, 0, 0.9);
    box-shadow: 0 3px 10px rgba(255, 255, 255, 0.41);
    color: #ffffff;
    text-shadow: rgba(0, 0, 0, 0.72) 0 0 3px;
    transition: all .2s ease-in-out;
    border: greenyellow 1px solid;
    border-radius: 2rem;
}

.MuiIconButton-root.refresh-button:hover {
    background: rgba(0, 143, 175, 0.93);
    box-shadow: 0 3px 10px rgba(255, 255, 255, 0.41);
    color: #ffffff;
    text-shadow: rgba(0, 0, 0, 0.72) 0 0 3px;
    transition: all .2s ease-in-out;
    border: rgba(47, 255, 248, 0.75) 1px solid;
    border-radius: 2rem;
}
.MuiIconButton-root.traveltime-button:hover {
    background: rgba(203, 203, 0, 0.79);
    box-shadow: 0 3px 10px rgba(255, 255, 255, 0.41);
    color: rgba(0, 0, 0, 0.48);
    text-shadow: rgba(210, 175, 0, 0.87) 0 0 3px;
    transition: all .2s ease-in-out;
    border: rgba(255, 255, 255, 0.69) 1px solid;
    border-radius: 2rem;
}



.MuiIconButton-root.cancel-button {
    background: var(--cancel-button);
    padding: .3rem 3.5rem;
    margin: .3rem;
    border-radius: 2rem;
    transition: all .4s ease-in-out;
    font-size: 1.2rem;
    color:  var(--cancel-button-text);
}

.MuiIconButton-root.cancel-button:hover {
    background: var(--cancel-button-hover);
    transition: all .2s ease-in-out;
    border-radius: 2rem;
    color:  var(--cancel-button-text);
}

.mainNavbar img:hover {
    transform: translateY(.5rem);
    transition: all ease .3s;

}

.mainNavbar {
    background: var(--nav-bar);
    display: block;
    height: 6vh;
    z-index: 10;
    position: absolute;
    top: 0;
    right: 0;
    transition: all ease 1s;
    width: 88vw;
    float: right;
}

.mainNavbar img{
    filter: var(--navbar-brightness);
    opacity: .7;
    transition: all ease .5s;
}

.mainNavbar img:hover{
    opacity: 1;
    transform: scale(1.3);
}
.inline{
    display: inline-block;
    width: 100%;

}
.logo{
    display: block;
    width: 12vw;
    position: absolute;
    top: 0;
}
.logo img{
    height: 6vh;
    content: var(--logo);
    float: right;
    padding: 0;
    margin-right: -.3rem;
    position: sticky;
}


button:disabled img{
    filter: grayscale(1);
}
.mainNavbar ul {
    position: absolute;
    top: -.5rem;
    right: 0;
}

.mainNavbar ul li {
    margin: 0 20px;
    list-style: none;
    position: relative;
    display: inline-block;
}

.mainNavbar .profile:after{
    content: url("./images/signout.png");
    padding: 0 0 0 .6rem;
    opacity: .8;
    vertical-align: top;
}
.mainNavbar .profile{
    border: 2px solid rgba(255, 255, 255, 0.44);
    margin: .3rem;
    padding: .5rem .7rem;
    height: 1rem;
    border-radius: 2rem;
    transform: translateY(-20%);
    font-weight: 500;
    text-transform: capitalize;
    transition: .2s all ease-in-out;
    color: rgba(255, 255, 255, 0.64);
    vertical-align: center;
}

.mainNavbar .profile:hover{
    background-color: rgba(255, 255, 255, 0.4);
    color: rgb(0, 0, 0);
    transition: .2s all ease-in-out;
    cursor: pointer;
}

.mainNavbar .profile:active{
    transform: scale(.95);
    cursor: pointer;
    content: 'Se déconnecter';
}

.mainNavbar li img:before {
    content: attr(alt);
}

.mainNavbar ul li a {
    color: var(--profile-name-background);
}


.mainNavbar ul li a img:active{
    transform: scale(.95);
    transition: .2s ease-in-out;
}

.mainBody {
    margin-top: 5vh;
    bottom: 0;
    display: block;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

@media (max-height:830px) {

}

.App-header {
    background-color: var(--app-header-background);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: var(--app-header);
}

.search__menu{
    position: static;
    display: block;
    overflow: visible;
}

canvas{
    cursor: pointer;
}

.App-link {
    color: var(--link);
}

.MuiTooltip-tooltip {
    color: rgb(255, 0, 0);
    padding: 4px 8px;
    font-size: 0.625rem;
    max-width: 300px;
    word-wrap: break-word;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.4em;
    border-radius: 4px;
    background-color: rgba(97, 97, 97, 0.9);
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px;
    border-radius: 1rem;
    background-color: rgba(65, 65, 65, 0.16);
    border: 1px solid whitesmoke;

}
::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.18);
    border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
    background: rgb(255, 255, 255);
    border-radius: .5rem;
    border: 1px solid rgba(107, 107, 107, 0.57);
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
