.react-responsive-modal-root {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
}

.react-responsive-modal-overlay {
    background: inherit;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.react-responsive-modal-modal> button {
    display: block;
    margin: auto;

}
.react-responsive-modal-modal> div> button {
    display: block;
    margin: auto;

}

.react-responsive-modal-container {
    height: 100%;
    outline: 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
}



/* Used to trick the browser to center the modal content properly  */
.react-responsive-modal-containerCenter:after {
    width: 0;
    filter: blur(8px)  brightness(1.2);
    height: 100%;
    content: '';
    display: inline-block;
    vertical-align: middle;
}

.react-responsive-modal-modal {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    backdrop-filter: blur(8px);
    box-shadow: 0 15px 25px 0 var(--shadow);
    margin: 1.2rem;
    padding: 1.2rem;
    position: relative;
    overflow-y: auto;
    min-width: 30vw;
    max-width: 100vw;
    max-height: 85vh;
    top: 3.5rem;
    border-radius: 20px;
    border: solid 1.5px var(--modal-border-color);
    background-color: var(--modal-background);
    z-index: 11;
}

.react-responsive-modal-modal >div >button{
    display: block;
    margin: auto;
}

.react-responsive-modal-closeButton {
    position: absolute;
    top: 14px;
    right: 14px;
    border: none;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    display: flex;
}

@keyframes react-responsive-modal-overlay-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes react-responsive-modal-overlay-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes react-responsive-modal-modal-in {
    0% {
        transform: scale(0.96);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes react-responsive-modal-modal-out {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0.96);
        opacity: 0;
    }
}

@-moz-document url-prefix() {
    .react-responsive-modal-container {
        background : url(../../images/bg-dark.png);
        background-color: rgba(0, 0, 0, 0.11);
    }
    .react-responsive-modal-modal {
        background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(120,120,120,.5), rgba(255,255,255,.7));

    }
}
