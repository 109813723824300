
.picture-grid{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap: 40px;
}

.picture-grid .border{
    border: 5px solid var(--grid-border) ;
    border-radius: 1rem;
}

.operation-picture{
    overflow: auto;
    max-height: fit-content;
    max-width: 20vw;
}

.operation-picture img{
    display: block;
    max-width: 5rem;
    max-height: 5rem;
    width: auto;
    height: auto;
}

.operation-picture .size{
    filter: opacity(50%);
    position: relative;
}

.operation-picture .size::after{
    content: 'X';
    display: block;
    position: absolute;
    font-size: 5rem;
    font-weight: bold;
    color: black;
    left: 50%;
    top: 50%;
    transform: translateY(50%) translateX(50%);
}

.picture{
    display: block;
}
.pictures-list{
    display: inline-flex;

}

.intervenantCell{
    overflow: auto;
    height: 100%;
    min-height: 5rem;
    max-height: 18vh;
    min-width: 16vw;
    justify-content: center;
    transition: .2s ease-in-out all;
}

.intervenantCell img{
    display: block;
    margin: auto;
}

textarea{
    border: 1px solid rgb(255, 255, 255);

}

textarea:hover{
    border: 1px solid rgba(0, 0, 0, 0.32);
    transition: .2s ease-in-out all;

}
tr.operation-row{
    position:relative
}

tr.operation-row textarea:focus-within{
    position: absolute;
    display: block;
    background-color: rgba(255, 255, 255, 0.85);
    z-index: 2;
    margin: auto;
    min-width: 30rem;
    max-width: 36rem;
    min-height: 36rem;
    max-height: 44rem;
    height: 7rem;
    font-size: 1rem;
    transform: translate(50px, -100px);
    transition: .5s ease-in-out all;
    border: 3px solid white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
}

tr.operation-row textarea{
    position: relative;
    padding: .5rem;
    font-family: rubik;
    font-size: .9rem;
    border-radius: .3rem;
    width: 90%;
    height: 7rem;
    max-height: 7rem;
    min-height: 7rem;
    min-width: 13rem;
    margin: .2rem .5rem;
    background-color: rgba(245, 245, 245, 0.47);

}
.operation-row td#comments{
    max-width: 6vw;
}
.popUpImage{
    max-height: 80vh;
}

.operation-row td:first-of-type{
    border-radius: .2rem;
    min-width: fit-content;
    display: contents;
    max-width: 10vw;
    background-color: rgba(138, 138, 138, 0.3);
    border: 1px rgba(227, 227, 227, 0.8) solid;
    padding: .3rem;
    text-align: center;
    margin: .5rem auto;
}


.operation-row td:first-of-type > * {
    padding: .8rem 0;
    margin: auto;
    display: block;
}

.operation-row td:first-of-type img:hover {
    background-color: rgba(255, 255, 255, 0.77);
    transition: .2s ease-in-out all;
    animation: pulse-white 1s infinite;
}

.operation-row td:first-of-type img {
    background-color: rgba(255, 255, 255, 0.45);
    border-radius: .5rem;
    padding: .2rem;
    border: rgba(255, 255, 255, 0.48) solid 1px;
    margin: .3rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: .2s ease-in-out all;
}

.operation-row td {
    min-width: 4vw;
    transition: .3s ease-in-out all;
}
.operation-row td #state{
    min-width: 40%;
}

.operation-row div#comments {
    display: flex;
}

.operation-row span.titleSection{
    color: var(--nav-bar);
    display: block;
    margin-left: 1rem;
    margin-bottom: .2rem;
    margin-top: .2rem ;
    text-decoration: underline;
    font-weight: bold;
    font-size: .8rem;
}

.operation-row td >div >div >div.section{
    border: 1px rgba(119, 119, 119, 0.45) dashed;
    border-radius: 0.5rem;
    margin: 0.2rem;
    padding: 0.3rem;
    background-color: rgba(255, 255, 255, 0.16);
}

.operation-row .intervenantCell > div{
    border: var(--td-cell-border-hover) 1px dashed;
    border-radius: .5rem;
    padding: .3rem;
    margin: .2rem
}

.operation-row td >div >div >div.section img{
    display: block;
    margin: 0 3rem;
}

.operation-row td:nth-child(1) {
    border: 1px solid white;
    background-color: red;
}

.operation-row td >div >div >div.section:hover{
    border: solid 1px rgba(255, 255, 255, 0.55);

}
.family img{
    height: fit-content;
    border-radius: 0.5rem;
    border: rgba(255, 255, 255, 0) solid 1px;
    cursor: pointer;
}
.family img:hover{
    border: white solid 1px;
}
.family:hover{
 border: 1px solid rgba(255, 255, 255, 0.76);
}

.family{
    overflow: auto;
    min-width: 3rem;
    max-width: 5rem;
    max-height: 100%;
    font-size: .8rem;
    word-break: break-all;
    text-overflow: ellipsis;
    display: inline-block;
    height: 8rem;
    margin: 4px;
    border: solid #fff 1px;
    border-radius: 0.5rem;
    padding: .3rem 1rem;
}

.family #text{
    font-size: .2rem;
}

.layer-img{
    max-width: 20rem;
    max-height: 20rem;
    width: auto;
    height: auto;
}



.export-operation > select{
    height: 2rem;
    width: 12rem;
    margin: 4rem auto;
    display: block;
    padding: .2rem;
    border-radius: .3rem;
    color: black;
    border-color: rgba(0, 0, 0, 0.56);


}