
.workForceButton *{
 font-size: .8rem;
}

.workForceButton span:first-of-type:after{
    content: url("./../images/bullet-icon.png");
}


.workForceButton:first-of-type{
    margin: 1rem;
}


.workForceButton{
    color: var(--text);
    background-color: rgba(255, 255, 255, 0.49);
    border-radius: .8rem;
    border: 0 solid white;
    margin: 1.5rem auto;
    padding: 0 .3rem;
    width: fit-content;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.workForceButton span{
    width: 3rem;
    margin: .2rem;
    padding: .1rem .5rem;
    text-align: center;

}
.workForceButton a:before{
    content: url("./../images/bullet-icon.png");
}

.workForceButton:hover{
    background-color: rgba(255, 255, 255, 0.63);
    border-radius: .5rem;
    color: var(--text);
    animation: box-shadow-animate 1s infinite;
    cursor: pointer;
    transition: all .5s ease-in-out;
}

@keyframes box-shadow-animate {
     0% {
         box-shadow: 0 0 0 0 var(--text);
     }

     50% {
         box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
     }

     100% {
         box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
     }
 }