.product:before{
    content: url("./../images/planning-pin.png");
}

.product{
    background: linear-gradient(0deg, rgba(208, 208, 208, 0.33), rgba(44, 44, 44, 0.29));
    border-radius: .8rem;
    padding: .5rem 1rem;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.21);
    border: rgba(0, 0, 0, 0.4) 1px solid;
}

.product span{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    max-height: 2rem;
    transition: all .5s ease-in-out;
}

.product .internalRef ,.product .quantity{
    opacity: 0;
    height: 0;
}

.product:hover .name{
    max-width: 16rem;
    max-height:10rem;
    overflow: auto;
}

.product .name{
    width: 16rem;
    height: fit-content;
    white-space: pre-line;
}

.product:hover .internalRef ,.product:hover .quantity{
    opacity: 1;
    height: 1rem;
}


.product:hover span:before{
    display: inline-block;
    content: attr(name)":";
    color: white;
    padding-right: .5rem;
}

