.layer-modal{
    display: inline-flex;
    justify-content: space-around;
    height: 80vh;
}


.layer-modal .layer-img{
 max-height: 100vh;
    max-width: 100vw;
    border-radius: 1rem;
    margin: 1rem;
    border: rgba(255, 255, 255, 0.47) 1px solid;
}

.layer-modal .metadata > ul{
    background-color: rgba(0, 0, 0, 0.13);
    border-radius: 1.5rem;
    padding: .5rem;
    border: 1px dashed #ffffff;
}

.layer-modal .metadata{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1rem;
    border: solid 1px white;
    border-radius: 1.8rem;
    background-color: rgba(255,255,255,.3);
    overflow: auto;
    gap: .2rem
}